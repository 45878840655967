import { Brand } from './typings'

const lowescan: Brand = {
  clientInfo: {
    displayName: 'Lowe’s Canada',
    accountNumberLengths: [16],
    footerDisplay: 'Lowes.ca',
    footerLink: 'https://www.lowes.ca/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'wHeSI9iP1AoywubQ6fAAXLP9kroZB2Ob',
      production: 'X7OtXLRpsJU6yNOfD1RrvSuCpYvWCF7m'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Access your Lowe’s Canada credit card account to make payments and manage your account preferences.`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Lowe’s Canada en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'Lowes.ca',
    hostname: 'lowes.syf.ca',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: false,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: "Your Lowe's® Consumer Credit Card is issued by Synchrony Financial Canada. The Synchrony Financial Canada Privacy Policy governs the use by Synchrony of the personal information related to Lowe's® Consumer Credit Card. The use of this site is governed by the Synchrony Financial Canada Internet Privacy Policy, which is different from the privacy policy of Lowe's.",
      es: ''
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-866-855-4429',
      dualcard: '',
      fraud: '1-866-855-4429',
      customerService: '1-866-855-4429'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: true,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@lowescan/en/html/ess020611.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@lowescan/en/html/ess020609.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@lowescan/en/html/ess020538.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@lowescan/en/html/ess020610.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#036096'
      },
      secondary: {
        hover: '#cfdde5'
      },
      background: '#0471af'
    },
    field: {
      icon: '#0471AF',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#0471AF',
      label: '#0471AF'
    },
    progressBar: {
      complete: '#0471AF',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#024990',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '',
    bodyBackground: '#FFFFFF',
    iconBackground: '#F2F2F2',
    headerBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '',
    headerDivider: '#024990',
    footerDivider: '#999999',
    contrastingText: '#FFFFFF',
    bodyText: '#333333',
    languageSelector: '#004990',
    focus: '#34657F',
    link: '#024990',
    footer: {
      background: '#004A90',
      text: '#FFFFFF',
      link: '#FFFFFF'
    }
  },
  images: {
    logo: {
      en: '/assets/lowes/logo.svg',
      es: '/assets/lowes/logo.svg'
    },
    hero: '/assets/lowes/background.png',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/lowescan/promo.png',
      es: '/assets/lowescan/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default lowescan
